import React from "react";
import {
	Box,
	Heading,
	Container,
	Text,
	VStack,
	UnorderedList,
	List,
	ListIcon,
	ListItem,
	Grid,
	Flex,
	Divider,
	AspectRatio,
} from "@chakra-ui/react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import MayorText from "src/components/MayorText";

const getData = graphql`
	{
		file(name: { eq: "Kingborough-Thrives-icon-monochromewhite" }) {
			publicURL
			childImageSharp {
				gatsbyImageData(
					width: 200
					placeholder: BLURRED
					formats: [AVIF, AUTO, WEBP]
				)
			}
		}
	}
`;

const Footer = () => {
	const data = useStaticQuery(getData);
	const tree = data.file.childImageSharp.gatsbyImageData;
	return (
		<Box as="footer" bg="blue.600" color="white">
			<Box mx="auto" w="87.5vw" maxW="64rem" pt="4rem" pb="2rem">
				<Box w="max-content" mx="auto">
					<Grid gridTemplateColumns={[null, null, "8rem 12.5rem 1fr"]}>
						<Box
							w={["50%", "50%", "100%"]}
							position="relative"
							right={["0rem", "0rem", "2rem"]}
							bottom={["0.5rem", "0.5rem", "0rem"]}
						>
							<MayorText />
						</Box>
						<Flex w="100%" h="100%" justify={["center", "center", "flex-end"]}>
							<Box
								w="12.5rem"
								borderRight={[null, null, "2px solid #FFD800"]}
								borderBottom={[
									"2px solid #FFD800",
									"2px solid #FFD800",
									"none",
								]}
								pr={[null, null, "2rem"]}
								pb={["2rem", "2rem", "0"]}
								as={Link}
								to="/"
							>
								<GatsbyImage image={tree} alt="kingborough thrives tree icon" />
							</Box>
						</Flex>
						<Flex w="100%" h="100%" align="center">
							<VStack
								w="max-content"
								pl={[null, null, "2rem"]}
								pt={["2rem", "2rem", 0]}
								align={["center", "center", "flex-start"]}
								spacing="1rem"
								fontWeight="bold"
								fontSize="lg"
							>
								<Text as={Link} to="/">
									Home
								</Text>
								<Text as={Link} to="/about/">
									About Clare
								</Text>
								<Text as={Link} to="/contact/">
									Get in touch
								</Text>
								<Text as={Link} to="/kingborough-thrives/">
									Kingborough Thrives
								</Text>
								<Text
									as="a"
									href="https://www.facebook.com/KingboroughThrives"
									rel="noopener"
									target="_blank"
								>
									Our Facebook page
								</Text>
							</VStack>
						</Flex>
					</Grid>
				</Box>
			</Box>
			<Text maxW="90vw" mx="auto" pb="2rem" textAlign="center">
				Authorised by Clare Glade-Wright 161 Cades Drive, Kingston
			</Text>
		</Box>
	);
};

export default Footer;
